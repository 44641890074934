import React, { useEffect } from 'react';
import ReactEmbedGist from 'react-embed-gist';

const IRSwitch = () => {
    return <>
        <h1>Multi-Port 9v IR Power Switch with Arduino</h1>
        <img id="cover-photo" src="/images/ir-switch-cover.png" />
        <p>
            The multi-port IR switch is a good project for those looking to experiment with integrated circuits.
            We build a series of low-side switches controlled by an Arduino via shift register and IR controller.
            Then, the ATmega is pulled from the board and everything is mounted into a project box.
            Some parts vary depending on which diagram you are viewing, so be sure to double check the datasheet for the specific part you are using.
        </p>
        <h2>How it works</h2>
        <h3>The Switches</h3>
        <p>
            The low side switch and shift register make up the backbone of this project.
        </p>
        <img className='project-image' src="/images/ir-switch-low-side-switch-demo.png" />
        <p>
            A low side switch is made using an NPN transistor.
            The role of the transistor is to keep the load at the collector disconnected from the emitter until a small current passes through the base.
            This can be used as a digital switch if the emitter is connected to ground.
            A shift register can be programmed to control multiple transistors in this configuration.
            Since shift registers deal with binary numbers, we can store the light output as a byte and it will make programming the logic easier.
        </p>
        <h3>The Display</h3>
        <p>Power connections have been omitted</p>
        <img className='project-image' src="/images/ir-switch-decoder-demo.png" />
        <p>
            We can treat four pins on the ATmega as a parallel port for half a byte, or a nibble, but the only problem is figuring out how to decode a decimal number.
        </p>
        <img className='project-image' src="/images/ir-switch-dec-to-binary-demo.png" />
        <p>
            In this example, we iterate exactly four times for each bit.
            Assume 'display' to be an array of pin numbers (2-5 for this example).
            To get the value of each bit, we shift the number 1 to its appropriate position and perform a bitwise and operation with the current light.
            If that particular bit is one, it will turn that pin on, otherwise it will be off.
            A better way to write this would be '(currentLight &gt;&gt; i) & 1' so that you get a pure one or zero.
        </p>
        <hr />
        <h2>Components you will need</h2>
        <ul>
            <li>Arduino</li>
            <li>IR Controller and Receiver</li>
            <li>74HC595 Shift Register</li>
            <li>2n2222a NPN Transistor (x8)</li>
            <li>10K Ohm Resistor (x8)</li>
            <li>74LS48 7-Segment Decoder</li>
            <li>220 Ohm Resistor (x7)</li>
            <li>7-Segment Display (Common Cathode)</li>
        </ul>
        <h2>Arduino Circuit</h2>
        <img className='project-image' src="/images/ir-switch-arduino.png" />
        <a style={{color: 'blue'}} 
           href="https://www.tinkercad.com/things/lsuW39WoyoJ-fantastic-waasa/editel?sharecode=sbsvgQL8ORT55aK10kOTJkWMvcBb9M7KXYEDxPsGgdc"
           target='_blank'><h3>&minus;&#62; Test this circuit live on Tinkercad! &#60;&minus;</h3></a>
        <h2>Connect to Arduino</h2>
        <p>
            VCC of IR Receiver to +5v<br />
            GND of IR Receiver to Ground<br />
            <br />
            Lamp Test (pin 3) of decoder to +5v<br />
            Blanking Input (pin 4) of decoder to +5v<br />
            Ripple Blanking Input (pin 5) of decoder to +5v<br />
            GND (pin 8) of decoder to Ground<br />
            VCC (pin 16) of decoder to +5v<br />
            <br />
            GND (pin 8) of shift register to Ground<br />
            Reset (pin 10) of shift register to +5v<br />
            Output Enable (pin 13) of shift register to Ground<br />
            VCC (pin 16) of shift register to +5v<br />
            <br />
            A (pin 7) of decoder to Arduino digital pin 2<br />
            B (pin 1) of decoder to Arduino digital pin 3<br />
            C (pin 2) of decoder to Arduino digital pin 4<br />
            D (pin 6) of decoder to Arduino digital pin 5<br />
            <br />
            Latch (pin 12) of shift register to Arduino digital pin 8<br />
            Data (pin 14) of shift register to Arduino digital pin 11<br />
            Clock (pin 11) of shift register to Arduino digital pin 12<br />
            <br />
            Output pin of the IR Receiver to Arduino digital pin 13
        </p>
        <p>
            Download <a target='_blank' href='https://github.com/Arduino-IRremote/Arduino-IRremote'>IRremote library</a> and install to Arduino libraries.<br />
            <br />
            Connect output pins (9-15) of decoder to 7 segment display through 220 ohm resistors.
            Connect cathodes of display to Ground.<br />
            <br />
            Connect output pins (1-7, 14) of shift register to bases of transistors through 10k resistors.
            Connect emitters of transistors to Ground.
            Connect the negative end of the load to the collector of the transistors and the positive end of the load to +9v.
            Make sure your 9v power supply shares a ground with the Arduino power supply.
        </p>
        <ReactEmbedGist gist="amaramirez/35c4523e1de51be43a46bff7b3a6677a"
                        wrapperClass='gist__bash'
                        loadingClass='loading__screen'
                        errorClass='gist__error'
                        contentClass='gist__content'
                        titleClass='gist__title'
        />
        <h2>If mounting the ATmega on a PCB, you will also need</h2>
        <ul>
            <li>L7805 5v Voltage Regulator</li>
            <li>16MHz Crystal</li>
            <li>10K Ohm Resistor</li>
            <li>22pf Ceramic Capacitor (x2)</li>
            <li>.22uf Electrolytic Capacitor</li>
            <li>.1uf Electrolytic Capacitor</li>
        </ul>
        <h2>Standalone Schematic</h2>
        <img className='project-image' src="/images/ir-switch-schematic.png" />
        <h3>DISCLAIMER</h3>
        <p><strong>The schematic does not feature short circuit protection. Be very careful how you wire and supply power to your ATmega.</strong></p>
    </>
}

export default IRSwitch;