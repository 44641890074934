import React from 'react';
import {
    IRSwitch,
    Landing
} from './components';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from 'react-router-dom';

const App = () => {
    return <Router>
        <header>
            <Link to='/'><h1>622 Project Schematics</h1>
            <h2>Supplementary information for build videos by Amadeo and friends.</h2></Link>
        </header>
        <main>
            <Routes>
                <Route path='/' element={<Landing />} />
                <Route path='/multiport-ir' element={<IRSwitch />} />
            </Routes>
        </main>
    </Router>
}

export default App;