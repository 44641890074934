import React from 'react';
import {
    Link
} from 'react-router-dom';

const Landing = () => {
    return <>
    <Link to="/multiport-ir">
        <div className='project-card'>
            <img src="/images/ir-switch-cover.png" />
            <div>
                <h3>Multi-Port 9v IR Power Switch with Arduino</h3>
                <p>Using a 74HC595 and an infrared receiver to control eight higher voltage, multi-purpose power switches.</p>
            </div>
            
        </div>
    </Link>
    </>
}

export default Landing;